import  * as OTPAuth from "otpauth";

let totp:any;
let token:any;

// Create new One Time Password
let createTotp = async () => {
    try {
        const SECRET = process.env.REACT_APP_2FA_BASE32;
        // create new OTP
        totp = new OTPAuth.TOTP({
            issuer: "Orfentik",
            label: "Jack By Orfentik",
            algorithm: "SHA1",
            digits: 6,
            period:30,
            secret: SECRET?.toString()
        });
        return totp.generate();
    } catch (error:any) {
        console.error(error.meassage)
    }
} 
// Get current token
let getToken = async () => {
    try {  
        return token;
    } catch (error:any) {
        console.error(error.message)
    }
}


// Get the URL for barcode image
let getBarCode = async () => {
    try {
        return await totp.toString(); 
    } catch (error:any) {
        console.error(error.message)
    }
}

const checkValidity = (token:any) => {
    let SECRET:any = process.env.REACT_APP_2FA_BASE32?.toString();
    try {
        let isValid = totp.validate({
            issuer: "Orfentik",
            label: "Jack By Orfentik",
            token,
            algorithm: "SHA1",
            digits: 6,
            period: 30,
            secret: OTPAuth.Secret.fromBase32(SECRET),
            window: 3
        });
        return isValid;
    } catch (error:any) {
        console.error(error.message)
    }
}

export {
    createTotp,
    getToken,
    getBarCode,
    checkValidity
}