import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Parse from 'parse';

// Initialise Parse
const PARSE_APPLICATION_ID = `${process.env.REACT_APP_PARSE_APPLICATION_ID}`;
const PARSE_JAVASCRIPT_KEY = `${process.env.REACT_APP_PARSE_JAVASCRIPT_KEY}`;
const PARSE_REST_API_KEY = `${process.env.REACT_APP_PARSE_REST_API_KEY}`;
const PARSE_SERVER_URL = `${process.env.REACT_APP_PARSE_SERVER_URL}`;
Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY, PARSE_REST_API_KEY);
Parse.serverURL = `${PARSE_SERVER_URL}`;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  <React.StrictMode>
      <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
