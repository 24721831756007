import React, {useEffect,Suspense} from 'react';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {CircularProgress} from '@mui/material';
import {createTotp} from './components/authentication/authenticator';

// Get pages
const Home    = React.lazy(() => import ("./components/home/home"));
const Signup  = React.lazy(() => import ("./components/authentication/auth-forms/signup"));
const Signin  = React.lazy(() => import ("./components/authentication/auth-forms/signin"));
const GoogleAuth  = React.lazy(() => import ("./components/authentication/providers/google/googleredirect"));
const XeroAuth  = React.lazy(() => import ("./components/authentication/providers/xero/xerocallback"));
const Settings  = React.lazy(() => import ("./components/settings/settings"));
const Jobmanagement  = React.lazy(() => import ("./components/jobmanagement/jobmanagement"));
const QuoteaAccepted = React.lazy(() => import ("./components/jobmanagement/quotes/quoteaccepted"));
const QuoteDeclined = React.lazy(() => import ("./components/jobmanagement/quotes/quotedeclined"));
const Payment  = React.lazy(() => import ("./components/payments/payment"));
const Paymentsuccess  = React.lazy(() => import ("./components/payments/paymentsuccess"));
const AdminDashboard  = React.lazy(() => import ("./components/jobmanagement/dashboards/admin"));
const UserDashboard  = React.lazy(() => import ("./components/jobmanagement/dashboards/user"));

// const UserChat  = React.lazy(() => import ("./components/chat/chat"));
// const Notifications = React.lazy(() => import ("./components/jobmanagement/notifications/view"));


function App() {

  useEffect(() => {
    // Create One time password
    createTotp();
 }, []);

  return (
    <Router>
      <Suspense fallback={<span><CircularProgress color="primary" size={'12px'} /></span>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/signin" element={<Signin/>}  />
            <Route path="/googleredirect" element={<GoogleAuth  />} />
            <Route path="/xerocallback" element={<XeroAuth  />} />
            <Route path="/settings" element={<Settings />}/>
            <Route path="/jobmanagement" element={<Jobmanagement />}  />
            <Route path="/quoteaccepted" element={<QuoteaAccepted />}  />
            <Route path="/quotedeclined" element={<QuoteDeclined />}  />
            <Route path="/payment" element={<Payment />}  />
            <Route path="/paymentsuccess" element={<Paymentsuccess />}  />
            <Route path="/admindashboard" element={<AdminDashboard mode={'light'} toggleColorMode={function (): void {throw new Error('Function not implemented.');}} />} />
            <Route path="/userdashboard" element={<UserDashboard mode={'light'} toggleColorMode={function (): void {throw new Error('Function not implemented.');}} />}  />
            {/* <Route path="/chat" element={<UserChat mode={'light'} toggleColorMode={function (): void { throw new Error('Function not implemented.'); } } chatRoom={''} loggedIn={false} />} /> */}
            {/* {/* <Route path="/notifications" element={<Notifications  />}  /> */}

          
          </Routes>
      </Suspense>
    </Router>

  );
}

export default App;
